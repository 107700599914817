import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Alert } from "react-bootstrap";
import {
  getAnswerDeletionReasonOptions,
  deleteAnswers, // Updated import
  updateAnswerDeletionReason,
} from "../services/APIClient.js"; // Adjust the path as necessary

const DeleteAnswerModal = ({
  show,
  handleClose,
  taskName,
  studentName,
  assessmentName,
  handleDelete,
  answerIds, // Updated to accept an array of answerIds
  teacherid,
  isRetakeFullAssessment,
}) => {
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [otherReason, setOtherReason] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [showConfirmation, setShowConfirmation] = useState(false); // New state to show confirmation modal
  const [deletionReasonText, setDeletionReasonText] = useState(""); // To store the final deletion reason text

  useEffect(() => {
    getAnswerDeletionReasonOptions().then(setOptions).catch(console.error);
  }, []);

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
    if (e.target.value !== "Other") {
      setOtherReason("");
    }
  };

  const handleOtherReasonChange = (e) => {
    setOtherReason(e.target.value);
  };

  const handleDeleteClick = () => {
    if (!selectedOption) {
      alert("Please select a reason for deletion.");
      return;
    }
    if (
      selectedOption ===
        options.find((option) => option.reason === "Other")?.id &&
      !otherReason
    ) {
      alert("Please provide a reason for deletion.");
      return;
    }

    // Store the deletion reason to display in the confirmation modal
    const reasonText =
      selectedOption === options.find((option) => option.reason === "Other")?.id
        ? otherReason
        : options.find((option) => option.id === selectedOption)?.reason;
    setDeletionReasonText(reasonText);

    // Show the confirmation modal
    setShowConfirmation(true);
  };

  // Handle final confirmation of deletion
  const onDelete = async () => {
    const reasonId = selectedOption;
    const deletionReason = deletionReasonText;

    const answerDeletedData = {
      teacherid: teacherid,
      answerids: answerIds, // Ensure this is correctly populated
      answerdeletionreasonid: reasonId,
      deletionreason: deletionReason,
    };

    console.log("Answer Deleted Data:", answerDeletedData);

    try {
      const deleteSuccess = await deleteAnswers(answerIds); // Ensure this is correctly called
      const updateSuccess = await updateAnswerDeletionReason(answerDeletedData);

      if (deleteSuccess && updateSuccess) {
        handleDelete(taskName, deletionReason);

        // Set success message
        setSuccessMessage(
          <>
            <p>{taskName} successfully reset.</p>
            <p>
              Please rescan {studentName} to retest {taskName}.
            </p>
          </>
        );

        setSelectedOption("");
        setOtherReason("");
        setShowConfirmation(false); // Hide confirmation modal

        // Clear success message and refresh after 3 seconds
        setTimeout(() => {
          window.location.reload();
          setSuccessMessage(""); // Clear message immediately
        }, 3000);
      } else {
        console.error("Failed to delete answers or update deletion reason");
      }
    } catch (error) {
      console.error("Error deleting answers and updating reason:", error);
    }
  };

  // Handle canceling the confirmation
  const handleCancelConfirmation = () => {
    setShowConfirmation(false); // Hide confirmation modal
  };

  return (
    <>
      <Modal show={show && !showConfirmation} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {successMessage
              ? "Success"
              : isRetakeFullAssessment
              ? "Retake Assessment"
              : "Retake Task"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {successMessage ? (
            <Alert variant="success">{successMessage}</Alert>
          ) : (
            <>
              <div className="modal-info">
                {!isRetakeFullAssessment && (
                  <p>
                    <strong>Task Name:</strong> {taskName}
                  </p>
                )}
                <p>
                  <strong>Student Name:</strong> {studentName}
                </p>
                <p>
                  <strong>Assessment Name:</strong> {assessmentName}
                </p>
              </div>
              <Form.Group className="mb-3">
                <Form.Label>
                  <strong>Reason for Retake</strong>
                </Form.Label>
                <Form.Control
                  as="select"
                  value={selectedOption}
                  onChange={handleOptionChange}
                >
                  <option value="" disabled>
                    Select a reason...
                  </option>
                  {options.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.reason}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              {selectedOption ===
                options.find((option) => option.reason === "Other")?.id && (
                <Form.Group className="mt-3">
                  <Form.Label>
                    <strong>Other Reason</strong>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    value={otherReason}
                    onChange={handleOtherReasonChange}
                    className="other-answer-option-width"
                  />
                </Form.Group>
              )}
            </>
          )}
        </Modal.Body>
        {!successMessage && (
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="danger" onClick={handleDeleteClick}>
              Retake {taskName}
            </Button>
          </Modal.Footer>
        )}
      </Modal>

      {/* Confirmation Modal */}
      <Modal
        show={showConfirmation}
        onHide={handleCancelConfirmation}
        className="confirmation-modal" // Add a custom class for the confirmation modal
        size="lg" // You can change this to 'xl' for even larger modal
        backdrop="static" // Prevent closing by clicking outside
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Retake</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            {isRetakeFullAssessment
              ? "Are you sure you want to retake the following assessment?"
              : "Are you sure you want to retake the following task?"}
          </p>
          {!isRetakeFullAssessment && (
            <p>
              <strong>Task Name:</strong> {taskName}
            </p>
          )}
          <p>
            <strong>Student Name:</strong> {studentName}
          </p>
          <p>
            <strong>Assessment Name:</strong> {assessmentName}
          </p>
          <p>
            <strong>Retake Reason:</strong> {deletionReasonText}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancelConfirmation}>
            Cancel
          </Button>
          <Button variant="danger" onClick={onDelete}>
            Confirm Retake
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DeleteAnswerModal;
