import React, { useState, useEffect, lazy, Suspense, useMemo } from "react";
import { Modal, Button, Row, Col, Form, Card } from "react-bootstrap";
const PrintAllStudentResults = lazy(() => import("./PrintAllStudentResults"));

function PrintAllStudentResultsModal({
    show,
    handleClose,
    studentResults,
    classDetails,
    classAssessments,
    assessment,
    readingScores,
    toggleScoreType,
    gradeLevel,
    teachers,
    sortCriteria,
    showAtRiskOnly,
    assessmentResultsByStudent,
    schoolid,
    teacherid,
    buttonName,
    isPrintOut,
    detailedView,
    students
}) {
    const [selectedStudents, setSelectedStudents] = useState([]);
    const [processedStudentResults, setProcessedStudentResults] = useState([]);

    useEffect(() => {
        const updatedStudentResults = Object.keys(studentResults)?.reduce((acc, studentId) => {
            const matchingStudent = students.find(student => student.id === studentId);
            const assessmentsWithStudentName = studentResults[studentId]?.map(assessment => ({
                ...assessment,
                studentId: studentId,
                studentName: matchingStudent ? matchingStudent.name : "Unknown Student",
            })).filter(assessment => assessment.tasks && assessment.tasks.length > 0);

            acc.push(...assessmentsWithStudentName);
            return acc;
        }, []);

        setProcessedStudentResults(updatedStudentResults);
        setSelectedStudents(updatedStudentResults.map(student => student.studentId));
    }, [studentResults, students]);

    const toggleStudentSelection = (studentId) => {
        setSelectedStudents(prevSelected => {
            const newSelection = prevSelected.includes(studentId)
                ? prevSelected.filter(id => id !== studentId)
                : [...prevSelected, studentId];
            console.log("Toggle selection: ", newSelection);
            return newSelection;
        });
    };

    const toggleSelectAll = () => {
        setSelectedStudents(prev => prev.length === processedStudentResults.length ? [] : processedStudentResults.map(student => student.studentId));
    };

    // Filter studentResults to include only those selected for printing
    const filteredStudentResults = useMemo(() => {
        const filteredResults = Object.keys(studentResults).reduce((acc, key) => {
            if (selectedStudents.includes(key)) {
                acc[key] = studentResults[key];
            }
            return acc;
        }, {});

        console.log("Filtered Student Results for Printing: ", filteredResults);
        return filteredResults;
    }, [studentResults, selectedStudents]);

    return (
        <Modal show={show} onHide={handleClose} size="lg" aria-labelledby="print-all-students-modal" centered>
            <Modal.Header closeButton>
                <Modal.Title id="print-all-students-modal">Select Students to Print</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col md={6}>
                        <div className="student-selection">
                            <Form.Check
                                type="checkbox"
                                label="Select All"
                                checked={selectedStudents.length === processedStudentResults.length}
                                onChange={toggleSelectAll}
                            />
                            <hr />
                            <div className="student-selection-list" style={{ maxHeight: "250px", overflowY: "auto" }}>
                                {processedStudentResults.map(student => (
                                    <Card key={student.studentId} className="mb-2">
                                        <Card.Body>
                                            <Form.Check
                                                type="checkbox"
                                                label={student.studentName || "Unknown Student"}
                                                checked={selectedStudents.includes(student.studentId)}
                                                onChange={() => toggleStudentSelection(student.studentId)}
                                            />
                                        </Card.Body>
                                    </Card>
                                ))}
                            </div>
                        </div>
                    </Col>
                    <Col md={6}>
                        <h5>Selected Students:</h5>
                        <ul>
                            {selectedStudents.map(id => {
                                const student = processedStudentResults.find(s => s.studentId === id);
                                return <li key={id}>{student?.studentName || "Unknown Student"}</li>;
                            })}
                        </ul>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>Close</Button>
                {selectedStudents.length > 0 && (

                    <Suspense fallback={<div>Loading...</div>}>
                        <PrintAllStudentResults
                            studentResults={filteredStudentResults}
                            classDetails={classDetails}
                            classAssessments={classAssessments}
                            gradeLevel={gradeLevel}
                            teachers={teachers}
                            assessment={assessment}
                            readingScores={readingScores}
                            toggleScoreType={toggleScoreType}
                            sortCriteria={sortCriteria}
                            showAtRiskOnly={showAtRiskOnly}
                            schoolid={schoolid}
                            teacherid={teacherid}
                            buttonName={buttonName}
                            isPrintOut={isPrintOut}
                            detailedView={detailedView}
                            assessmentResultsByStudent={assessmentResultsByStudent}
                            students={students}
                        />
                    </Suspense>
                )}

            </Modal.Footer>
        </Modal>
    );
}

export default PrintAllStudentResultsModal;
