import { React, useState } from "react";
import { Container, Col, Row } from "react-bootstrap";

import "./IndividualStudentResultsOverview.css";
import "./ClassOverview.css";

import ReturnToClassResults from "./RetrunToClassResults";
import AllResultsHistory from "./AllResultsHistory"; // Import new component
import GradeCard from "./GradeCard";
import PrintAllStudentResults from "./PrintAllStudentResults";
import RecommendationsButton from "./RecommendationsButton";
import PrintAllStudentResultsModal from "./PrintAllStudentResultsModal";

function AllStudentResultsOverview(props) {
  const {
    classDetails,
    studentResults,
    assessmentResultsByStudent,
    schoolid,
    teacherid,
    classAssessments,
    teachers,
    assessment,
    students,
  } = props;

  const {
    grade_level1: gradeLevel1,
    grade_level2: gradeLevel2,
    trial_class: trialClass,
  } = classDetails;

  const [toggleScoreType, setToggleScoreType] = useState("");
  const [showPrintModal, setShowPrintModal] = useState(false);


  return (
    <div className="class-overview-container">
      <Container className="container-shape">
        <Row className="page-description-bar">
          <Col className="col-6">
            <Row>
              <Col className="col-6">
                <h2 className="page-description-header">{classDetails.name}</h2>
              </Col>
              <Col className="col-4">
                <GradeCard
                  gradeLevel={props.gradeLevel}
                  gradeLevel1={classDetails.grade_level1}
                  gradeLevel2={classDetails.grade_level2}
                  trialClass={classDetails.trial_class}
                />
              </Col>
            </Row>
            <p>
              View the Results for a class, click on a student to see a more
              detailed breakdown.
            </p>
          </Col>
          <Col className="col-4">
            <Row>
              <Col className="col-3"></Col>
              <Col className="col-3">
                <ReturnToClassResults
                  classDetails={classDetails}
                  teachers={teachers}
                  classAssessments={classAssessments}
                  assessment={assessment}
                  schoolid={schoolid}
                  teacherid={teacherid}
                />
              </Col>
              <Col className="col-1"></Col>
              <Col className="col-4">
                <RecommendationsButton onResultsPage={true} />
              </Col>
            </Row>
          </Col>
        </Row>

        <hr />

        <Row>
          <Col className="col-3">
            <button className="blue-button" onClick={() => setShowPrintModal(true)}>
              Print
            </button>
            {/* <PrintAllStudentResults
              studentResults={studentResults}
              toggleScoreType={toggleScoreType}
              assessmentResultsByStudent={assessmentResultsByStudent}
              isPrintOut={true}
              detailedView={true}
              teacherid={teacherid}
              schoolid={schoolid}
              students={students}
              buttonName={"Print Detailed"}
              classDetails={classDetails}
            /> */}
          </Col>
        </Row>

        {/* Render AllResultsHistory without any conditions */}
        <AllResultsHistory
          studentResults={studentResults} // Pass the student results
          toggleScoreType={toggleScoreType}
          assessmentResultsByStudent={assessmentResultsByStudent}
          isPrintOut={false}
          detailedView={true}
          teacherid={teacherid}
          schoolid={schoolid}
          students={students}
        />
      </Container>
      <PrintAllStudentResultsModal
        show={showPrintModal}
        assessmentResultsByStudent={assessmentResultsByStudent}
        handleClose={() => setShowPrintModal(false)}
        students={students}
        classDetails={classDetails}
        classAssessments={classAssessments}
        assessment={assessment}
        toggleScoreType={toggleScoreType}
        teachers={teachers}
        studentResults={studentResults}
        isPrintOut={true}
        detailedView={true}
        teacherid={teacherid}
        schoolid={schoolid}
        buttonName={"Print Detailed"}
      />
    </div>
  );
}

export default AllStudentResultsOverview;
