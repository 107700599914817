import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
  Suspense,
} from "react";
import { Link, useNavigate } from "react-router-dom";
import Card from "react-bootstrap/Card";
import HeadingBanner from "../components/HeadingBanner";
import { Container, Row, Col, Modal, Button } from "react-bootstrap";
import {
  whoami,
  getClasses,
  getTeachers,
  getSchools,
  shareClass,
  updateClassOwnerId,
  postLastLogin,
  getLastLogin,
  getTrialistsBySchool,
  createTrialistBySchoolid,
  getCountryGrades,
} from "../services/APIClient";
import { Fallback } from "../services/SpinnerComponent";
import "../components/ClassCard.css";
import "../components/MasterDashCards.css";

import OrientationPrompt from "../components/OrientationPrompt";

// Lazy Loaded Components
const LeftPanel = React.lazy(() => import("../components/LeftPanel"));
const AdditionalColleaguesButtonMD = React.lazy(() =>
  import("../components/AdditionalColleaguesButtonMD")
);
const AdditionalColleaguesButtonMD2 = React.lazy(() =>
  import("../components/AdditionalColleaguesButtonMD2")
);
const ReferAFriendButton = React.lazy(() =>
  import("../components/ReferFriendsButton")
);
const IntroVideoButton = React.lazy(() =>
  import("../components/IntroVideoButton")
);
const AlpacaInfoGesture = React.lazy(() =>
  import("../components/AlpacaInfoGesture")
);
const FreeTrialBanner = React.lazy(() =>
  import("../components/FreeTrialBanner")
);

function MasterDashboard() {
  const [schoolid, setSchoolid] = useState(null);
  const [teacherid, setTeacherid] = useState(null);
  const [name, setName] = useState("");
  const [teachers, setTeachers] = useState(null);
  const [teacherRoles, setTeacherRoles] = useState([]);
  const [gradeLevelOptions, setGradeLevelOptions] = useState([]);
  const [firstname, setFirstname] = useState(null);
  const [speechText, setSpeechText] = useState(null);
  const [classes, setClasses] = useState([]);
  const [classid, setClassid] = useState([]);
  const [rollNumber, setRollNumber] = useState(null);
  const [teacherEmail, setTeacherEmail] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);

  const [trialistDetails, setTrialistDetails] = useState(null);
  const [freeDaysLeft, setFreeDaysLeft] = useState(null);
  const [showTrialEndedModal, setShowTrialEndedModal] = useState(false);
  const { expired = false, purchased = false } = trialistDetails || {};

  const [isPortrait, setIsPortrait] = useState(
    window.matchMedia("(orientation: portrait)").matches
  );
  const [sharedClassId, setSharedClassId] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const initialize = async () => {
      try {
        const savedSharedClassId = localStorage.getItem("sharedClassId");
        const loggedInAlready = localStorage.getItem("loggedInAlready");
        const newUserSignup = localStorage.getItem("newUserSignup");

        if (savedSharedClassId) {
          setSharedClassId(savedSharedClassId);
        }

        const whoamiResponse = await whoami();
        const { schoolid, id, name, additionalRoles, countryid } =
          whoamiResponse.data.stakeholder;

        setSchoolid(schoolid);
        setTeacherid(id);
        setName(name);
        setTeacherEmail(whoamiResponse.data.email);
        setTeacherRoles(additionalRoles);

        const gradesResponse = await getCountryGrades(countryid);
        setGradeLevelOptions(gradesResponse);

        if (loggedInAlready === "true") {
          return;
        }

        const lastLoginData = await getLastLogin(id);
        const lastLogin = lastLoginData
          ? new Date(lastLoginData.lastloggedin)
          : null;
        const thresholdDate = new Date("2024-09-01T00:00:00Z");

        await postLastLogin(id);
        localStorage.setItem("loggedInAlready", "true");

        if (newUserSignup === "true") {
          return;
        }

        if (!lastLogin || lastLogin < thresholdDate) {
          navigate(`/profile`, {
            state: {
              teacherid: id,
              profilePageRedirect: true,
            },
          });
        }
      } catch (error) {
        console.error("Error during initialization:", error);
      }
    };

    initialize();
  }, [navigate]);

  useEffect(() => {
    if (schoolid) {
      Promise.all([getTeachers(schoolid), getClasses(), getSchools()])
        .then(([teachersResponse, classesResponse, schoolsResponse]) => {
          setTeachers(teachersResponse);
          setClasses(classesResponse);
          setClassid(classesResponse.id);

          const matchingSchool = schoolsResponse.data.find(
            (school) => school.id === schoolid
          );

          if (matchingSchool) {
            setRollNumber(matchingSchool.rollnumber);
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [schoolid]);

  useEffect(() => {
    if (name) {
      setFirstname(name.split(" ")[0]);
    }
  }, [name]);

  useEffect(() => {
    setSpeechText(
      `Hello ${firstname}, let's start by setting up a class. Click 'Admin' to begin or use the button above my head to explore the Dashboard.`
    );
  }, [firstname]);

  useEffect(() => {
    if (sharedClassId && teacherid) {
      shareClass(sharedClassId, teacherid, true)
        .then(() => updateClassOwnerId(sharedClassId, teacherid))
        .then(() => {
          localStorage.removeItem("sharedClassId");
        })
        .catch((error) => {
          console.error("Error sharing or updating class owner ID:", error);
        });
    }
  }, [sharedClassId, teacherid]);

  useEffect(() => {
    const fetchTrialistDetails = async () => {
      try {
        const response = await getTrialistsBySchool(schoolid);
        if (response.length > 0) {
          const details = response[0];
          setTrialistDetails(details);

          const currentDate = new Date();
          const endDate = new Date(details.end_date);
          const daysLeft = Math.ceil(
            (endDate - currentDate) / (1000 * 60 * 60 * 24)
          );
          setFreeDaysLeft(daysLeft > 0 ? daysLeft : 0);
        } else {
          const trialid = "1ed3aa96-d938-46a0-85bc-9fb98529c529"; // Static trialid
          const trialistData = await createTrialistBySchoolid(
            schoolid,
            trialid
          );
          setTrialistDetails(trialistData);

          const currentDate = new Date();
          const endDate = new Date(trialistData.end_date);
          const daysLeft = Math.ceil(
            (endDate - currentDate) / (1000 * 60 * 60 * 24)
          );
          setFreeDaysLeft(daysLeft > 0 ? daysLeft : 0);
        }
      } catch (error) {
        console.error("Failed to fetch trialist details:", error);
      }
    };

    if (schoolid) {
      fetchTrialistDetails();
    }
  }, [schoolid]);

  const handleCloseTrialEndedModal = () => {
    setShowTrialEndedModal(false);
  };

  const handleSENCOView = useCallback(() => {
    navigate("/sencoresults/detail", {
      state: {
        teachers,
        name,
        gradeLevelOptions,
        teacherid,
        schoolid,
      },
    });
  }, [navigate, teachers, name, gradeLevelOptions, teacherid, schoolid]);

  useEffect(() => {
    const handleOrientationChange = () => {
      setIsPortrait(window.innerWidth < window.innerHeight);
    };

    window.addEventListener("resize", handleOrientationChange);

    return () => {
      window.removeEventListener("resize", handleOrientationChange);
    };
  }, []);

  return (
    <div>
      <Container fluid className="vh-100">
        <HeadingBanner
          name={`Welcome ${name}!`}
          isLoggedIn={true}
          cloud={true}
          teacherid={teacherid}
          purchased={purchased}
        />
        <Row className="h-100">
          <Suspense fallback={<div>Loading Left Panel...</div>}>
            <LeftPanel />
          </Suspense>
          <Col sm={{ span: 10, offset: 2 }} className="blue-background">
            {loading ? (
              <div className="spinner-container">
                <div className="big-ball-spinner"></div>
              </div>
            ) : (
              <>
                {classes.length === 0 ? (
                  <Row>
                    <Suspense fallback={<div>Loading Intro Video...</div>}>
                      <IntroVideoButton />
                    </Suspense>
                    <Col className="col-3"></Col>
                    <Suspense
                      fallback={
                        <div>Loading Additional Colleagues Button...</div>
                      }
                    >
                      <AdditionalColleaguesButtonMD2
                        rollNumber={rollNumber}
                        teacherEmail={teacherEmail}
                      />
                    </Suspense>
                  </Row>
                ) : (
                  <Row className="invite-colleagues-row-position">
                    {!purchased ? (
                      <FreeTrialBanner
                        schoolid={schoolid}
                        teacherName={name}
                        freeDaysLeft={freeDaysLeft}
                        trialistDetails={trialistDetails}
                      />
                    ) : null}
                    <div className="additional-colleague-button ">
                      <div className="assessment-reminder-content hide-assessment-banner">
                        <Link
                          className="ass-heading-link "
                          to={`/assessmentsdashboard`}
                          state={{
                            classDetails: { classes },
                            teachers: { teachers },
                            name: { name },
                          }}
                        >
                          <img
                            src="../assets/imgs/spring-icon.png"
                            className="assessment-reminder-card-img"
                            alt="Colleague Icon"
                          />
                          <p className="ass-heading-card-text">
                            Midpoint Assessment Season is Here! <br /> Time to
                            Assign and Shine!
                          </p>
                          <img
                            src="../assets/imgs/spring-icon.png"
                            className="assessment-reminder-card-img"
                            alt="Colleague Icon"
                          />
                        </Link>
                      </div>
                    </div>
                    <Col>
                      <Suspense
                        fallback={
                          <div>Loading Additional Colleagues Button...</div>
                        }
                      >
                        <AdditionalColleaguesButtonMD
                          rollNumber={rollNumber}
                          teacherEmail={teacherEmail}
                        />
                      </Suspense>
                    </Col>
                    <Col>
                      <Suspense
                        fallback={<div>Loading Refer A Friend Button...</div>}
                      >
                        <ReferAFriendButton
                          rollNumber={rollNumber}
                          teacherEmail={teacherEmail}
                          teacherid={teacherid}
                          name={name}
                        />
                      </Suspense>
                    </Col>
                  </Row>
                )}
                <Row className="masterdash-row-position">
                  <Col className="col-3">
                    <Card className="masterdash-card-detail">
                      <Link
                        className="link"
                        to={`/admindashboard`}
                        state={{
                          classDetails: { classes },
                          teachers: { teachers },
                          name: { name },
                          rollNumber: { rollNumber },
                        }}
                      >
                        <img
                          src="./assets/imgs/admin-icon.png"
                          className="master-class-card-img"
                          alt="Admin Icon"
                        ></img>
                        <p className="class-card-text">Admin</p>
                      </Link>
                    </Card>
                  </Col>
                  {classes.length === 0 ? (
                    <Suspense
                      fallback={<div>Loading ALpaca Info Gesture...</div>}
                    >
                      <AlpacaInfoGesture speechText={speechText} />
                    </Suspense>
                  ) : (
                    <>
                      <Col className="col-3">
                        <Card className={`masterdash-card-detail`}>
                          <Link
                            className="link"
                            to={`/assessmentsdashboard`}
                            state={{
                              classDetails: { classes },
                              teachers: { teachers },
                              name: { name },
                            }}
                          >
                            <div>
                              <img
                                src="./assets/imgs/assignassessments-icon.png"
                                className="master-class-card-img"
                                alt="Assign Assessments Icon"
                              ></img>
                              <p className="class-card-text">Assign Screener</p>
                            </div>
                          </Link>
                        </Card>
                      </Col>

                      <Col className="col-3">
                        <Card className="masterdash-card-detail">
                          <Link
                            className="link"
                            to={`/qrdashboard`}
                            state={{
                              classDetails: { classes },
                              teachers: { teachers },
                              name: { name },
                            }}
                          >
                            <img
                              src="./assets/imgs/qrs-icon.png"
                              className="master-class-card-img"
                              alt="Student QRs Icon"
                            ></img>
                            <p className="class-card-text">Student QRs</p>
                          </Link>
                        </Card>
                      </Col>
                      <Col className="col-3">
                        <Card className="masterdash-card-detail">
                          {teacherRoles?.includes("SENCO") ? (
                            <a
                              href="#"
                              className="link"
                              onClick={(e) => {
                                e.preventDefault(); // Prevent default link behavior
                                handleSENCOView();
                              }}
                            >
                              <img
                                src="./assets/imgs/viewresults-icon.png"
                                className="master-class-card-img"
                                alt="View Results Icon"
                              ></img>
                              <p className="class-card-text">View Results</p>
                            </a>
                          ) : (
                            <Link
                              className="link"
                              to={`/resultsdashboard`}
                              state={{
                                classDetails: { classes },
                                teachers: { teachers },
                                name: { name },
                              }}
                            >
                              <img
                                src="./assets/imgs/viewresults-icon.png"
                                className="master-class-card-img"
                                alt="View Results Icon"
                              ></img>
                              <p className="class-card-text">View Results</p>
                            </Link>
                          )}
                        </Card>
                      </Col>
                    </>
                  )}
                </Row>
              </>
            )}
          </Col>
        </Row>
      </Container>

      {isPortrait && <OrientationPrompt />}
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        dialogClassName="fun-vibrant-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Assessment Window Closed</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          The current assessment window is now closed. We will be reopening the
          ALPACA assessment for the <strong>End of Year</strong> assessment
          window on the <strong>7th May</strong>.
        </Modal.Body>
        <Modal.Body>Thank you for your understanding.</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Trial Ended Modal */}
      <Modal show={showTrialEndedModal} onHide={handleCloseTrialEndedModal}>
        <Modal.Header closeButton>
          <Modal.Title>Access Restricted</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Your free trial has ended. To continue using ALPACA's features, please
          purchase a subscription.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleCloseTrialEndedModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default MasterDashboard;
