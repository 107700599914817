import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
  lazy,
  Suspense,
} from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import {
  getClass,
  getStudents,
  getAllStudentsResultsPerAssessment,
  updateUserActivity,
} from "../services/APIClient";
import HeadingBanner from "../components/HeadingBanner";
import OrientationPrompt from "../components/OrientationPrompt";
import { Container, Row, Col } from "react-bootstrap";
import { BigSpinner } from "../services/SpinnerComponent";

// Lazy load components for better performance
const LeftPanel = React.lazy(() => import("../components/LeftPanel"));
const StudentsResultsOverview = React.lazy(() =>
  import("../components/StudentsResultsOverview")
);

// Error Boundary Component
// Error Boundary Component
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    console.error("Error in component:", error, errorInfo);
    this.setState({ error, errorInfo });
  }

  render() {
    if (this.state.hasError) {
      const { error, errorInfo } = this.state;

      return (
        <div style={{ padding: "1em", backgroundColor: "#f8d7da", color: "#842029", borderRadius: "5px" }}>
          <h2>Oops! Something went wrong.</h2>
          <p>
            An error occurred while loading the component. Please contact
            us at <a href="mailto:info@alpaca-assessment.com">info@alpaca-assessment.com</a> with the following details:
          </p>
          <details style={{ whiteSpace: "pre-wrap", marginTop: "1em" }}>
            <summary>Error Details</summary>
            <p><strong>Error:</strong> {error?.toString()}</p>
            <p>
              <strong>Stack Trace:</strong>
              <br />
              {errorInfo?.componentStack || "No additional information available."}
            </p>
          </details>
        </div>
      );
    }
    return this.props.children;
  }
}


function StudentsResults() {
  const location = useLocation();

  const name = "Results";
  const locationState = location.state || {};
  const classDetails = locationState.classDetails || {};
  const gradeLevel = locationState.gradeLevel || "Unknown Grade";
  const classAssessments = locationState.classAssessments || [];
  const teachers = locationState.teachers || [];
  const assessment = locationState.assessment || {};
  const assessmentInstances = assessment.instances || {};

  const schoolid = locationState.schoolid || "";
  const teacherid = locationState?.teacherid || "";
  const classid = classDetails.id || "";

  const [studentsResponse, setStudentsResponse] = useState([]);
  const [studentResults, setStudentResults] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true); // Added loading state

  const [isPortrait, setIsPortrait] = useState(
    window.matchMedia("(orientation: portrait)").matches
  );

  // Memoized `studentIds` to avoid unnecessary recalculations
  const studentIds = useMemo(
    () => studentsResponse.map((student) => student.id),
    [studentsResponse]
  );

  // Fetch students based on `classid`
  useEffect(() => {
    if (!classid) return;
    // setLoading(true);
    getStudents(classid)
      .then((response) => setStudentsResponse(response?.students || []))
      .catch((err) => {
        console.error("Failed to fetch students:", err);
        setError("Failed to fetch students.");
      });
    // .finally(() => setLoading(false));
  }, [classid]);

  useEffect(() => {
    if (!classid || !assessmentInstances) return;

    const fetchData = async () => {
      try {
        // Fetch students first
        const studentsResponse = await getStudents(classid);
        setStudentsResponse(studentsResponse?.students || []);

        // Fetch results based on fetched students
        const studentIds = (studentsResponse?.students || []).map(
          (student) => student.id
        );

        // console.log("studentIds:", studentIds);
        // console.log("assessmentInstances:", assessmentInstances);

        const studentResults = await getAllStudentsResultsPerAssessment(
          studentIds,
          assessmentInstances
        );

        console.log("studentResults:", studentResults);

        setStudentResults(studentResults || []);
      } catch (err) {
        console.error("Error fetching data:", err);
        setError("Failed to fetch data.");
      }
    };

    fetchData();
  }, [classid, assessmentInstances]);

  // Handle orientation changes
  useEffect(() => {
    const handleOrientationChange = () => {
      setIsPortrait(window.innerWidth < window.innerHeight);
    };

    window.addEventListener("resize", handleOrientationChange);

    return () => {
      window.removeEventListener("resize", handleOrientationChange);
    };
  }, []);

  // if (loading) {
  //   return <BigSpinner />;
  // }

  console.log("teacherid:", teacherid)
  console.log("locationState:", locationState)


  console.log("studentResults:", studentResults)


  return (
    <Container fluid className="vh-100">
      <HeadingBanner
        name={name || "Results"}
        isLoggedIn={true}
        cloud={true}
        teacherid={teacherid || "N/A"}
      />
      <Row className="h-100">
        <Suspense fallback={<BigSpinner />}>
          <ErrorBoundary>
            <LeftPanel />
          </ErrorBoundary>
        </Suspense>
        <Col sm={{ span: 10, offset: 2 }} className="blue-background">
          <Suspense fallback={<BigSpinner />}>
            <ErrorBoundary>
              <StudentsResultsOverview
                classDetails={classDetails}
                classAssessments={
                  Array.isArray(classAssessments) ? classAssessments : []
                } // Ensure array
                students={studentsResponse}
                gradeLevel={gradeLevel}
                teachers={teachers}
                assessment={assessment}
                assessmentInstanceID={assessmentInstances}
                schoolid={schoolid}
                teacherid={teacherid}
                studentResults={studentResults}
              />
            </ErrorBoundary>
          </Suspense>
        </Col>
      </Row>
      {isPortrait && <OrientationPrompt />}
    </Container>
  );
}

export default StudentsResults;

// StudentsResults.propTypes = {
//   location: PropTypes.shape({
//     state: PropTypes.shape({
//       classDetails: PropTypes.object,
//       gradeLevel: PropTypes.string,
//       classAssessments: PropTypes.array,
//       teachers: PropTypes.array,
//       assessment: PropTypes.object,
//       assessmentInstanceID: PropTypes.string,
//       schoolid: PropTypes.string,
//       teacherid: PropTypes.string,
//     }),
//   }),
// };

// export default StudentsResults;

// import { React, useState, useEffect } from "react";

// import { useLocation, useParams } from "react-router-dom";

// import {
//   getClass,
//   getStudents,
//   getAllStudentsResultsPerAssessment,
//   updateUserActivity
// } from "../services/APIClient";

// import HeadingBanner from "../components/HeadingBanner";
// import LeftPanel from "../components/LeftPanel";
// import StudentsResultsOverview from "../components/StudentsResultsOverview";
// import OrientationPrompt from "../components/OrientationPrompt";

// import { Container, Row, Col } from "react-bootstrap";

// function StudentsResults(props) {
//   const location = useLocation();

//   let name = "Results";
//   console.log(location, "LOCATION");

//   // Handle undefined state
//   let classDetails = location.state?.classDetails?.classDetails || {};
//   let gradeLevel = location.state?.gradeLevel?.gradeLevel || "";
//   let classAssessments =
//     location.state?.classAssessments?.classAssessments || [];
//   // let teacherid = classDetails.teacherId || "";
//   let teachers = location.state?.teachers?.teachers || [];
//   let assessment = location.state?.assessment?.assessment || {};
//   let assessmentInstanceID =
//     location.state?.assessmentInstanceID?.assessmentInstanceID || "";
//   let assessmentInstances =
//     location.state?.assessment?.assessment.instances || {};

//   const [studentsResponse, setStudentsResponse] = useState("");
//   const [error, setError] = useState(null);
//   const [studentIds, setStudentIds] = useState([]);
//   const [studentResults, setStudentResults] = useState([]);

//   console.log(classAssessments, "STATUS");

//   let schoolid = location.state?.schoolid?.schoolid;
//   let teacherid = location.state?.teacherid?.teacherid;
//   let classid = classDetails.id;

//   useEffect(() => {
//     getStudents(classid).then((response) =>
//       setStudentsResponse(response.students)
//     );
//   }, [classid]);

//   const [isPortrait, setIsPortrait] = useState(
//     window.matchMedia("(orientation: portrait)").matches
//   );

//   useEffect(() => {
//     const handleOrientationChange = () => {
//       setIsPortrait(window.innerWidth < window.innerHeight);
//     };

//     window.addEventListener("resize", handleOrientationChange);

//     return () => {
//       window.removeEventListener("resize", handleOrientationChange);
//     };
//   }, []);

//   useEffect(() => {
//     window.HubSpotConversations?.widget?.refresh();
//   }, []);

//   useEffect(() => {
//     window.HubSpotConversations?.widget?.refresh();
//   }, []);

//   useEffect(() => {
//     // Extract student IDs whenever studentsResponse changes
//     if (Array.isArray(studentsResponse)) {
//       const ids = studentsResponse.map((student) => student.id);
//       setStudentIds(ids);
//     }
//   }, [studentsResponse]);

//   useEffect(() => {
//     // Fetch results when studentIds and assessmentInstanceID are available

//     getAllStudentsResultsPerAssessment(studentIds, assessmentInstances)
//       .then((data) => {
//         setStudentResults(data);
//         console.log("fired");
//       })
//       .catch((error) => {
//         console.error("Error fetching results:", error);
//         setError("Error fetching results");
//       });
//   }, [studentIds, assessmentInstances]);

//   useEffect(() => {
//     // Fetch results when studentIds and assessmentInstanceID are available

//     updateUserActivity(teacherid, "results-viewed")
//   }, [studentIds, assessmentInstances]);

//   return (
//     <Container fluid className="vh-100">
//       <HeadingBanner
//         name={name}
//         isLoggedIn={true}
//         cloud={true}
//         teacherid={teacherid}
//       />
//       <Row className="h-100">
//         <LeftPanel />
//         <Col sm={{ span: 10, offset: 2 }} className="blue-background">
//           <StudentsResultsOverview
//             classDetails={classDetails}
//             classAssessments={classAssessments}
//             // teachers={location.state.teachers}
//             // sharedWith={sharedWith}
//             students={studentsResponse}
//             gradeLevel={gradeLevel}
//             teachers={teachers}
//             assessment={assessment}
//             assessmentInstanceID={assessmentInstanceID}
//             schoolid={schoolid}
//             teacherid={teacherid}
//             studentResults={studentResults}
//             // name={location.state.name.name}
//           />
//         </Col>
//       </Row>
//       {isPortrait && <OrientationPrompt />}
//     </Container>
//   );
// }

// export default StudentsResults;